<template>
  <div class="page">
    <el-container>
      <el-header>

        <Head :slide="slide" @logout="logout"></Head>
      </el-header>
      <el-main>
        <div class="">
          <ul class="bread-crumb">
            <li class="item item-1 fill" @click="$router.push('/post/upload?id=' + id)">
              {{ $t('report.post') + $t('report.upload') }}
            </li>
            <li class="item item-2 fill" @click="$router.push('/post/scale?id=' + id)">
              {{ $t('report.post') + $t('report.scale') }}
            </li>
            <li class="item item-3 fill" @click="$router.push('/post/level?id=' + id)">
              {{ $t('report.post') + $t('report.level') }}
            </li>
            <li class="item item-3 fill">
              {{ $t('report.post') + $t('report.measure') }}
            </li>
            <li class="item item-3">
              {{ $t('report.post') + $t('report.install') }}
            </li>
            <li class="item item-4">
              {{ $t('report.post') + $t('report.stand') }}
            </li>
            <li class="item item-5">
              {{ $t('report.post') + $t('report.create') }}
            </li>
          </ul>

          <div class="main">
            <div class="left-side">

              <!-- <h2 class="content-title">
                        <div class="title">
                            <span>{{$t('report.step')}}</span>
                        </div>
                    </h2>
                     <ul class="step-list">
                        <li class="item">
                            1.{{$t('report.level')}}
                        </li>
                        <li class="item active">
                            2.{{$t('report.measure')}}
                        </li>
                        <li class="item">
                            3.{{$t('report.install')}}
                        </li>
                    </ul> -->

              <el-form label-width="100px" class="form" label-position="left">
                <h2 class="content-title">
                  <div class="title">
                    <span>{{ $t('report.dist') }}（mm）:</span>
                  </div>
                </h2>
                <el-form-item label-width="auto" label="">
                  <el-input oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" disabled
                    v-model="actual"></el-input>
                </el-form-item>
                <h2 class="content-title">
                  <div class="title">
                    <span>{{ $t('report.reference') }}:</span>
                  </div>
                </h2>
                <el-form-item label-width="auto">
                  <el-select v-model="reference" :placeholder="$t('report.placeholder')">
                    <el-option v-for="item in refOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>


            </div>
            <div class="canvas-box" style="position: relative">
              <div class="container"
                :style="{ 'width': oldWidth + 'px', 'height': oldHeight + 'px', 'overflow': 'auto' }">
                <v-stage :config="configKonva" ref="stage">
                  <v-layer ref="layer">
                    <v-image ref="xFront" :config="stageImage" />
                    <v-line ref="lineReference1" :config="configReference1" @mouseleave="lineReference1MouseLeave"
                      @mouseenter="lineReference1MouseEnter($event)"></v-line>
                    <v-line ref="linelevel1" :config="lineLevel" @mouseenter="linelevel1MouseEnter($event)"></v-line>
                    <v-circle ref="circleSide1" :config="configCircleFront"></v-circle>
                    <v-line ref="distanceList1" :config="distanceList"
                      @mouseenter="distanceList1MouseEnter($event)"></v-line>
                    <v-text ref="distanceText" :config="distanceText"></v-text>
                    <v-group ref="frontTopGroup" :config="configFrontTopTool">
                      <v-line ref="frontTopLine1" :config="frontTopLine1"
                        @mouseenter="frontTopLine1MouseEnter($event)"></v-line>
                      <v-line ref="frontTopLine2" :config="frontTopLine2"></v-line>
                      <v-circle ref="frontTopL1" :config="frontTopL1"></v-circle>
                      <v-circle ref="frontTopR1" :config="frontTopR1"></v-circle>
                      <v-circle ref="frontTopL2" :config="frontTopL2"></v-circle>
                      <v-circle ref="frontTopR2" :config="frontTopR2"></v-circle>
                      <v-line ref="frontTopLineCenter" :config="frontTopLineCenter"
                        @mouseleave="frontTopLineCenterMouseLeave"
                        @mouseenter="frontTopLineCenterMouseEnter($event)"></v-line>

                    </v-group>
                    <v-group ref="frontBottomGroup" :config="configFrontBottomTool">
                      <v-line ref="frontBottomLine1" :config="frontBottomLine1"
                        @mouseenter="frontBottomLine1MouseEnter($event)"></v-line>
                      <v-line ref="frontBottomLine2" :config="frontBottomLine2"
                        @mouseenter="frontBottomLine2MouseEnter($event)"></v-line>
                      <v-circle ref="frontBottomL1" :config="frontBottomL1"></v-circle>
                      <v-circle ref="frontBottomR1" :config="frontBottomR1"></v-circle>
                      <v-circle ref="frontBottomL2" :config="frontBottomL2"></v-circle>
                      <v-circle ref="frontBottomR2" :config="frontBottomR2"></v-circle>
                      <v-line ref="frontBottomLineCenter" :config="frontBottomLineCenter"
                        @mouseenter="frontBottomLineCenterMouseEnter($event)" @mouseleave="frontBottomLineCenterMouseLeave"></v-line>
                      <v-circle ref="frontSection" :config="frontSection"  @mouseleave="frontSectionMouseLeave"
                        @mouseenter="frontSectionMouseEnter($event)"></v-circle>
                    </v-group>

                    <!-- <v-group ref="frontBottomGroup" :config="configFrontAngleBottomTool">
                      <v-line ref="frontAngleBottomLine2" :config="frontAngleBottomLine2"></v-line>
                      <v-line ref="frontAngleBottomLine1" :config="frontAngleBottomLine1"></v-line>
                      <v-circle ref="frontAngleBottomL1" :config="frontAngleBottomL1"></v-circle>
                      <v-circle ref="frontAngleBottomR1" :config="frontAngleBottomR1"></v-circle>
                      <v-circle ref="frontAngleBottomL2" :config="frontAngleBottomL2"></v-circle>
                    </v-group> -->

                  </v-layer>
                </v-stage>

              </div>
              <Slide :slide="frontSlide" direction="front"></Slide>
              <div class="btn-restore" @click="onRestore">{{ $t('report.restore') }}</div>
              <!-- <div class="btn-restore" @click="onAngleMeasure" style="left: 90px;">{{ $t('report.angleMeasure') }}</div> -->
              <Zoom class="zoom" @onIncrease="onIncrease" @onReset="onReset" @onReduce="onReduce"></Zoom>
            </div>
            <div class="canvas-box" style="position: relative">
              <div class="container"
                :style="{ 'width': oldWidth + 'px', 'height': oldHeight + 'px', 'overflow': 'auto' }">
                <v-stage :config="configKonva2" ref="stage2">
                  <v-layer ref="layer2">
                    <v-image ref="xSide" :config="sideImage" />>
                    <v-line ref="lineReference2" :config="configReference2"
                      @mouseenter="lineReference2MouseEnter($event)" @mouseleave="llineReference2MouseLeave"></v-line>
                    <v-line ref="linelevel2" :config="lineLevel" @mouseenter="linelevel2MouseEnter($event)"></v-line>
                    <v-circle ref="circleSide2" :config="configCircleSide"
                      @mouseenter="circleSide2MouseEnter($event)"></v-circle>
                    <v-line ref="distanceList2" :config="distanceList"
                      @mouseenter="distanceList2MouseEnter($event)"></v-line>
                    <v-text ref="distanceText" :config="distanceText"></v-text>
                    <v-group ref="sideTopGroup" :config="configSideTopTool">
                      <v-line ref="sideTopLine1" :config="sideTopLine1"></v-line>
                      <v-line ref="sideTopLine2" :config="sideTopLine2"></v-line>
                      <v-circle ref="sideTopL1" :config="sideTopL1"></v-circle>
                      <v-circle ref="sideTopR1" :config="sideTopR1"></v-circle>
                      <v-circle ref="sideTopL2" :config="sideTopL2"></v-circle>
                      <v-circle ref="sideTopR2" :config="sideTopR2"></v-circle>
                      <v-line ref="sideTopLineCenter" :config="sideTopLineCenter" @mouseleave="sideTopLineCenterMouseLeave"
                        @mouseenter="sideTopLineCenterMouseEnter($event)"></v-line>
                    </v-group>
                    <v-group ref="sideBottomGroup" :config="configSideBottomTool">
                      <v-line ref="sideBottomLine1" :config="sideBottomLine1"></v-line>
                      <v-line ref="sideBottomLine2" :config="sideBottomLine2"></v-line>
                      <v-circle ref="sideBottomL1" :config="sideBottomL1"></v-circle>
                      <v-circle ref="sideBottomR1" :config="sideBottomR1"></v-circle>
                      <v-circle ref="sideBottomL2" :config="sideBottomL2"></v-circle>
                      <v-circle ref="sideBottomR2" :config="sideBottomR2"></v-circle>
                      <v-line ref="sideBottomLineCenter" :config="sideBottomLineCenter"
                        @mouseleave="sideBottomLineCenterMouseLeave"
                        @mouseenter="sideBottomLineCenterMouseEnter($event)"></v-line>
                      <v-circle ref="sideSection" :config="sideSection" @mouseleave="sideSectionMouseLeave"
                        @mouseenter="sideSectionMouseEnter($event)"></v-circle>
                    </v-group>
                  </v-layer>
                </v-stage>

              </div>
              <Slide :slide="sideSlide" direction="side"></Slide>
              <div class="btn-restore" @click="onRestore">{{ $t('report.restore') }}</div>
              <!-- <div class="btn-restore" @click="onAngleMeasure" style="left: 90px;">{{ $t('report.angleMeasure') }}</div> -->
              <Zoom class="zoom" @onIncrease="onIncrease" @onReset="onReset" @onReduce="onReduce"></Zoom>
            </div>

            <div class="right-side">
              <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="form" label-position="left">
                <div class="green">
                  <h2 class="content-title">
                    <div class="title green">
                      <span>{{ $t('report.theFront') }}</span>
                    </div>
                  </h2>
                  <el-form-item label-width="150px" class="green" :label="$t('report.angle') + '（°）:'"><el-input
                      :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.front.angle"></el-input></el-form-item>
                  <el-form-item label-width="150px" label="" class="green">
                    <el-radio-group :disabled="lock" v-model="ruleForm.front.angleDir">
                      <el-radio class="radio" label="1">{{ $t('report.param1') }}</el-radio>
                      <el-radio class="radio" label="0">{{ $t('report.param2') }}</el-radio>

                    </el-radio-group>
                  </el-form-item>
                  <el-form-item class="green" label-width="150px" :label="$t('report.frontDistance') + '（mm）:'"><el-input
                      :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.front.distance"></el-input></el-form-item>
                  <el-form-item class="green" label-width="150px" label="">
                    <el-radio-group :disabled="lock" v-model="ruleForm.front.distanceDir">
                      <el-radio class="radio" label="0">{{ $t('report.param3') }}</el-radio>
                      <el-radio class="radio" label="1">{{ $t('report.param4') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>

                <div class="yellow">
                  <h2 class="content-title">
                    <div class="title yellow">
                      <span>{{ $t('report.theSide') }}</span>
                    </div>
                  </h2>
                  <el-form-item class="yellow" label-width="150px" :label="$t('report.angle') + '（°）:'"><el-input
                      :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.side.angle"></el-input></el-form-item>
                  <el-form-item class="yellow" label-width="150px" label="">
                    <el-radio-group :disabled="lock" v-model="ruleForm.side.angleDir">
                      <el-radio class="radio" label="0">{{ $t('report.param5') }}</el-radio>
                      <el-radio class="radio" label="1">{{ $t('report.param6') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item class="yellow" label-width="150px" :label="$t('report.sideDistance') + '（mm）:'"><el-input
                      :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.side.distance"></el-input></el-form-item>
                  <el-form-item class="yellow" label-width="150px" label="">
                    <el-radio-group :disabled="lock" v-model="ruleForm.side.distanceDir">
                      <el-radio class="radio" label="0">{{ $t('report.param7') }}</el-radio>
                      <el-radio class="radio" label="1">{{ $t('report.param8') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>



                <div class="purple">
                  <h2 class="content-title">
                    <div class="title purple">
                      <span>{{ $t('report.theAxis') }}</span>
                    </div>
                  </h2>
                  <el-form-item class="purple" label-width="150px" :label="$t('report.angle') + '（°）:'"><el-input
                      :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.axis.angle"></el-input></el-form-item>
                  <el-form-item class="purple" label-width="150px" label="">
                    <el-radio-group :disabled="lock" v-model="ruleForm.axis.angleDir">
                      <el-radio class="radio" label="0">{{ $t('report.param9') }}</el-radio>
                      <el-radio class="radio" label="1">{{ $t('report.param10') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item class="purple" label-width="150px" :label="$t('report.axisDistance') + '（mm）:'"><el-input
                      :disabled="lock" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      v-model="ruleForm.axis.distance"></el-input></el-form-item>
                  <el-form-item class="purple" label-width="150px" label="">
                    <el-radio-group :disabled="lock" v-model="ruleForm.axis.distanceDir">
                      <el-radio class="radio" label="0">{{ $t('report.param11') }}</el-radio>
                      <el-radio class="radio" label="1">{{ $t('report.param12') }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <div :class="['lock', { 'lock-fill': lock }]" @click="lock = !lock">
                  <img :src="lock ? require('../../assets/img/ziyuanxhdpi.png') : require('../../assets/img/icon_6.png')"
                    alt="">
                  <span>{{ lock ? $t('report.unlock') : $t('report.lock') }}</span>
                </div>
                <div class="btn-submit" @click="dialogVisible = true">{{ $t('report.nextStep') }}</div>
                <div class="btn-submit" @click="complete">{{ $t('report.complete') }}</div>
                <div class="text-upload" @click="lastStep">{{ $t('report.lastStep') }}</div>
              </el-form>

            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog :title="$t('report.paramsConfirm')" :visible.sync="dialogVisible" width="30%" center>
      <div class="dialog">
        <ul>
          <li>{{ $t('report.theFront') }}</li>
          <li>
            {{ ruleForm.front.angleDir == '0' ? $t('report.param2') : $t('report.param1') }} {{ ruleForm.front.angle + '°'
            }}
          </li>
          <li>
            {{ ruleForm.front.distanceDir == '0' ? $t('report.param3') : $t('report.param4') }} {{ ruleForm.front.distance
              +
              'mm' }}
          </li>
        </ul>
        <ul>
          <li>{{ $t('report.theSide') }}</li>
          <li>
            {{ ruleForm.side.angleDir == '0' ? $t('report.param5') : $t('report.param6') }} {{ ruleForm.side.angle + '°'
            }}
          </li>
          <li>
            {{ ruleForm.side.distanceDir == '0' ? $t('report.param7') : $t('report.param8') }} {{ ruleForm.side.distance +
              'mm' }}
          </li>
        </ul>
        <ul>
          <li>{{ $t('report.theAxis') }}</li>
          <li>
            {{ ruleForm.axis.angleDir == '0' ? $t('report.param9') : $t('report.param10') }} {{ ruleForm.axis.angle + '°'
            }}
          </li>
          <li>
            {{ ruleForm.axis.distanceDir == '0' ? $t('report.param11') : $t('report.param12') }} {{ ruleForm.axis.distance
              +
              'mm' }}
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('report.BackToRevise') }}</el-button>
        <el-button type="primary" @click="submit">{{ $t('report.ConfirmData') }}</el-button>
      </span>
    </el-dialog>
    <div class="popover" :style="popoverPosition" v-show="popoverShow">
      <div v-html="popoverContent"></div>
    </div>
  </div>
</template>
<script>
import Zoom from '../../components/Zoom';
export default {
  inject: ['reload'],
  components: {
    Zoom
  },
  data () {
    return {
      isComplete: false,
      popoverShow: false,
      popoverPosition: { top: 0, left: 0 },
      popoverContent: '',
      dialogVisible: false, //确认弹出框
      id: '', //病例id 
      ruleForm: {
        front: {
          angle: 0, //正位角度
          distance: 0, //正位位移
          angleDir: '0', //角度方向
          distanceDir: '0', //位移方向

        },
        side: {
          angle: 0,
          distance: 0,
          angleDir: '0',
          distanceDir: '0',

        },
        axis: {
          angle: 0,
          distance: 0,
          angleDir: '0',
          distanceDir: '0',

        }
      },
      stage: null, //画布
      layer: null, //画笔
      stage2: null, //画布2
      layer2: null, //画笔2
      frontImageObj: new Image(), //正位初始图片对象
      sideImageObj: new Image(), //侧位初始图片对象
      oldWidth: document.documentElement.clientWidth * 0.32, // 画布初始宽度
      oldHeight: document.documentElement.clientHeight * 0.8, // 画布初始高度
      widthIn: document.documentElement.clientWidth * 0.32, // 当前画布宽度
      heightIn: document.documentElement.clientHeight * 0.8, // 当前画布高度
      widthIn2: document.documentElement.clientWidth * 0.32,
      heightIn2: document.documentElement.clientHeight * 0.8,
      xRay: null,  //正位图
      xRayS: null, //侧位图
      multiple: 1,
      scale: 1,
      base: [],
      isConfirm: false,
      disabled: false,
      actual: 0, //参考线距离mm
      dist: 0, //参考线距离px
      frontTopTool: null, //正位上工具
      frontBottomTool: null, //正位下工具
      sideTopTool: null, //侧位上工具
      sideBottomTool: null, //侧位下工具
      slideText: '',
      slide: '',
      frontSlide: '0', // 正位患侧
      sideSlide: '0', // 侧位患侧
      reference: 0, //参考节段
      refOptions: [ //参考节段选项
        {
          value: 0,
          label: this.$t('report.ref1')
        }, {
          value: 1,
          label: this.$t('report.ref2')
        }
      ],
      lock: false, //是否锁定
      in_green: false,
      in_red: false
    }
  },

  computed: {
    //画布配置
    configKonva () {
      return {
        width: this.widthIn,
        height: this.heightIn,
        // draggable: true 
      }
    },
    configKonva2 () {
      return {
        width: this.widthIn2,
        height: this.heightIn2,
        // draggable: true 
      }
    },
    //正位图配置
    stageImage () {
      return {
        x: this.configKonva.width / 2,
        y: this.configKonva.height / 2,
        rotation: 0, //旋转角度
        image: this.frontImageObj,
        offset: {
          x: this.frontImageObj.width / 2,
          y: this.frontImageObj.height / 2
        },
      }
    },
    //侧位图配置
    sideImage () {
      return {
        x: this.configKonva.width / 2,
        y: this.configKonva.height / 2,
        rotation: 0, //旋转角度
        image: this.sideImageObj,
        offset: {
          x: this.sideImageObj.width / 2,
          y: this.sideImageObj.height / 2
        },
      }
    },
    //水平线
    lineLevel () {
      let level = this.base.length && this.base[0].point ? JSON.parse(this.base[0].point).y : 0;
      return {
        points: [0, level, this.configKonva.width, level],
        stroke: '#208EFF',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [5 / this.scale, 5 / this.scale],
      }
    },
    //参考线
    configReference1 () {
      const that = this;

      return {
        points: [0, this.dist, this.configKonva.width, this.dist],
        stroke: '#FF0A0A',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [5 / this.scale, 5 / this.scale],
        draggable: !this.lock,
        dragBoundFunc: function (pos) {

          let y = that.dist + pos.y / that.scale;
          // that.actual = Math.floor(y / that.base[0].proportion * 100) / 100;
          that.countActual(y);
          that.$refs.circleSide1.getNode().y(y);
          that.$refs.circleSide2.getNode().y(y);
          that.$refs.lineReference2.getNode().y(pos.y / that.scale)
          that.drawDistLine(y)
          that.layer.draw();
          that.layer2.draw();
          setTimeout(that.frontDataChange, 10);
          setTimeout(that.sideDataChange, 10);
          return {
            x: this.absolutePosition().x,
            y: pos.y
          };
        }
      }
    },
    configReference2 () {
      const that = this;
      return {
        points: [0, this.dist, this.configKonva2.width, this.dist],
        stroke: '#FF0A0A',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [5 / this.scale, 5 / this.scale],
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          let y = that.dist + pos.y / that.scale;
          // that.actual = Math.floor(y / that.base[0].proportion * 100) / 100;
          that.countActual(y);
          that.$refs.circleSide1.getNode().y(y);
          that.$refs.circleSide2.getNode().y(y);
          that.$refs.lineReference1.getNode().y(pos.y / that.scale)
          that.drawDistLine(y)
          that.layer.draw();
          that.layer2.draw();
          setTimeout(that.frontDataChange, 10);
          setTimeout(that.sideDataChange, 10);
          return {
            x: this.absolutePosition().x,
            y: pos.y
          };
        }
      }
    },
    configCircleFront () {
      const that = this;
      return {
        x: this.oldWidth / 5,
        y: this.dist,
        radius: 10 / this.scale,
        fill: '#208EFF',
        stroke: '#FFFFFF',
        strokeWidth: 2 / this.scale,
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          let y = pos.y / that.scale;
          // that.actual = Math.floor(y / that.base[0].proportion  * 100) / 100;
          that.countActual(y);
          that.$refs.circleSide2.getNode().y(y);
          that.$refs.lineReference1.getNode().y(y - that.dist)
          that.$refs.lineReference2.getNode().y(y - that.dist)
          that.drawDistLine(y)
          that.layer.draw();
          that.layer2.draw();
          setTimeout(that.frontDataChange, 10);
          setTimeout(that.sideDataChange, 10);
          return {
            x: this.absolutePosition().x,
            y: pos.y
          };
        }
      }
    },
    configCircleSide () {
      const that = this;
      return {
        x: this.oldWidth / 5,
        y: this.dist,
        radius: 10 / this.scale,
        fill: '#208EFF',
        stroke: '#FFFFFF',
        strokeWidth: 2 / this.scale,
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          let y = pos.y / that.scale;
          // that.actual = Math.floor(y / that.base[0].proportion * 100) / 100;
          that.countActual(y);
          that.$refs.circleSide1.getNode().y(y);
          that.$refs.lineReference1.getNode().y(y - that.dist)
          that.$refs.lineReference2.getNode().y(y - that.dist)
          that.drawDistLine(y)
          that.layer.draw();
          that.layer2.draw();
          setTimeout(that.frontDataChange, 10);
          setTimeout(that.sideDataChange, 10);
          return {
            x: this.absolutePosition().x,
            y: pos.y
          };
        }
      }
    },
    //距离线
    distanceList () {
      let dist = this.$refs.lineReference1 ? this.$refs.lineReference1.getNode().y() + this.dist : this.dist;
      let level = this.base.length && this.base[0].point ? JSON.parse(this.base[0].point).y : 0;
      return {
        points: [this.widthIn - 50, level, this.widthIn - 50, dist],
        stroke: 'blue',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [5 / this.scale, 5 / this.scale],

      }
    },
    //距离线数据
    distanceText () {
      let distabce = this.$refs.distanceList1 ? this.$refs.distanceList1.getNode().points() : this.distanceList.points;
      return {
        x: this.widthIn - 100,
        y: (distabce[3] + distabce[1]) / 2,
        text: this.actual + 'mm',
        fontSize: 14 / this.scale,
        fill: '#FFFFFF'
      }
    },
    //正位上骨骼工具
    configFrontTopTool () {
      const that = this;
      return {
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          setTimeout(that.frontDataChange, 10);
          return {
            x: that.$refs.frontTopL1.getNode().x() + pos.x / that.scale < 0
              ? - that.$refs.frontTopL1.getNode().x() * that.scale
              : that.$refs.frontTopR1.getNode().x() + pos.x / that.scale > that.widthIn
                ? this.absolutePosition().x
                : pos.x,
            y: that.$refs.frontTopL1.getNode().y() + pos.y / that.scale < 0
              ? - that.$refs.frontTopL1.getNode().y() * that.scale
              : that.$refs.frontTopL2.getNode().y() + pos.y / that.scale > that.heightIn
                ? this.absolutePosition().y
                : pos.y,
          }
        }
      }
    },
    //正位上骨骼工具-左上角
    frontTopL1 () {
      return {
        x: this.oldWidth / 2 - 50,
        y: this.oldHeight / 2 - 150,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.frontTopLine1.points[0] =  this.$refs.frontTopL1.getNode().x();
          // this.frontTopLine1.points[1] = this.$refs.frontTopL1.getNode().y();
          this.$refs.frontTopLine1.getNode().points(
            [
              pos.x - this.frontTopTool.x(),
              pos.y - this.frontTopTool.y(),
              this.$refs.frontTopLine1.getNode().points()[2],
              this.$refs.frontTopLine1.getNode().points()[3],
            ]
          )
          // this.frontTopLineCenter.points[2] = (this.$refs.frontTopL1.getNode().x() + this.$refs.frontTopR1.getNode().x()) / 2;
          // this.frontTopLineCenter.points[3] = (this.$refs.frontTopL1.getNode().y() + this.$refs.frontTopR1.getNode().y()) / 2;
          this.setFrontTopPoint();
          setTimeout(this.frontDataChange, 10)
          return pos;
        }
      }
    },
    //正位上骨骼工具-右上角
    frontTopR1 () {
      return {
        x: this.oldWidth / 2 + 50,
        y: this.oldHeight / 2 - 150,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.frontTopLine1.points[2] = this.$refs.frontTopR1.getNode().x();
          // this.frontTopLine1.points[3] = this.$refs.frontTopR1.getNode().y();
          this.$refs.frontTopLine1.getNode().points(
            [
              this.$refs.frontTopLine1.getNode().points()[0],
              this.$refs.frontTopLine1.getNode().points()[1],
              pos.x - this.frontTopTool.x(),
              pos.y - this.frontTopTool.y(),
            ]
          )
          // this.frontTopLineCenter.points[2] = (this.$refs.frontTopL1.getNode().x() + this.$refs.frontTopR1.getNode().x()) / 2;
          // this.frontTopLineCenter.points[3] = (this.$refs.frontTopL1.getNode().y() + this.$refs.frontTopR1.getNode().y()) / 2;
          this.setFrontTopPoint();
          setTimeout(this.frontDataChange, 10)
          return pos;
        }
      }
    },
    //正位上骨骼工具-左下角
    frontTopL2 () {
      return {
        x: this.oldWidth / 2 - 50,
        y: this.oldHeight / 2 - 100,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.frontTopL2.x = pos.x - this.frontTopTool.x() - this.stage.x();
          // this.frontTopL2.y = pos.y - this.frontTopTool.y() - this.stage.y();
          // this.frontTopLine2.points[0] = this.$refs.frontTopL2.getNode().x();
          // this.frontTopLine2.points[1] = this.$refs.frontTopL2.getNode().y();
          this.$refs.frontTopLine2.getNode().points(
            [
              pos.x - this.frontTopTool.x(),
              pos.y - this.frontTopTool.y(),
              this.$refs.frontTopLine2.getNode().points()[2],
              this.$refs.frontTopLine2.getNode().points()[3],
            ]
          )
          // this.frontTopLineCenter.points[4] = (this.$refs.frontTopL2.getNode().x() + this.$refs.frontTopR2.getNode().x()) / 2;
          // this.frontTopLineCenter.points[5] = (this.$refs.frontTopL2.getNode().y() + this.$refs.frontTopR2.getNode().y()) / 2;
          this.setFrontTopPoint();
          setTimeout(this.frontDataChange, 10)
          return pos;
        }
      }
    },
    //正位上骨骼工具-右下角
    frontTopR2 () {
      return {
        x: this.oldWidth / 2 + 50,
        y: this.oldHeight / 2 - 100,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.frontTopR2.x = pos.x - this.frontTopTool.x() - this.stage.x();
          // this.frontTopR2.y = pos.y - this.frontTopTool.y() - this.stage.y();
          // this.frontTopLine2.points[2] = this.$refs.frontTopR2.getNode().x();
          // this.frontTopLine2.points[3] = this.$refs.frontTopR2.getNode().y();
          this.$refs.frontTopLine2.getNode().points(
            [
              this.$refs.frontTopLine2.getNode().points()[0],
              this.$refs.frontTopLine2.getNode().points()[1],
              pos.x - this.frontTopTool.x(),
              pos.y - this.frontTopTool.y(),
            ]
          )
          // this.frontTopLineCenter.points[4] = (this.$refs.frontTopL2.getNode().x() + this.$refs.frontTopR2.getNode().x()) / 2;
          // this.frontTopLineCenter.points[5] = (this.$refs.frontTopL2.getNode().y() + this.$refs.frontTopR2.getNode().y()) / 2;
          this.setFrontTopPoint();
          setTimeout(this.frontDataChange, 10)
          return pos;
        }
      }
    },
    //正位上骨骼工具-顶边
    frontTopLine1 () {
      return {
        points: [
          this.$refs.frontTopL1 ? this.$refs.frontTopL1.getNode().x() : this.frontTopL1.x,
          this.$refs.frontTopL1 ? this.$refs.frontTopL1.getNode().y() : this.frontTopL1.y,
          this.$refs.frontTopR1 ? this.$refs.frontTopR1.getNode().x() : this.frontTopR1.x,
          this.$refs.frontTopR1 ? this.$refs.frontTopR1.getNode().y() : this.frontTopR1.y,
        ],
        stroke: '#00BF99',
        strokeWidth: 4 / this.scale,

      }
    },
    //正位上骨骼工具-底边
    frontTopLine2 () {
      return {
        points: [
          this.$refs.frontTopL2 ? this.$refs.frontTopL2.getNode().x() : this.frontTopL2.x,
          this.$refs.frontTopL2 ? this.$refs.frontTopL2.getNode().y() : this.frontTopL2.y,
          this.$refs.frontTopR2 ? this.$refs.frontTopR2.getNode().x() : this.frontTopR2.x,
          this.$refs.frontTopR2 ? this.$refs.frontTopR2.getNode().y() : this.frontTopR2.y,
        ],
        stroke: '#00BF99',
        strokeWidth: 4 / this.scale,
      }
    },
    //正位上骨骼工具-中线
    frontTopLineCenter () {
      return {
        points: this.getPoint(
          this.$refs.frontTopL1 ? this.$refs.frontTopL1.getNode().x() : this.frontTopL1.x,
          this.$refs.frontTopL1 ? this.$refs.frontTopL1.getNode().y() : this.frontTopL1.y,
          this.$refs.frontTopR1 ? this.$refs.frontTopR1.getNode().x() : this.frontTopR1.x,
          this.$refs.frontTopR1 ? this.$refs.frontTopR1.getNode().y() : this.frontTopR1.y,
          this.$refs.frontTopL2 ? this.$refs.frontTopL2.getNode().x() : this.frontTopL2.x,
          this.$refs.frontTopL2 ? this.$refs.frontTopL2.getNode().y() : this.frontTopL2.y,
          this.$refs.frontTopR2 ? this.$refs.frontTopR2.getNode().x() : this.frontTopR2.x,
          this.$refs.frontTopR2 ? this.$refs.frontTopR2.getNode().y() : this.frontTopR2.y,
        ),
        stroke: '#00BF99',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [10 / this.scale, 4 / this.scale],
      }
    },

    //正位下骨骼工具
    configFrontBottomTool () {
      const that = this;
      return {
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          setTimeout(that.frontDataChange, 10)
          return {
            x: that.$refs.frontBottomL1.getNode().x() + pos.x / that.scale < 0
              ? - that.$refs.frontBottomL1.getNode().x() * that.scale
              : that.$refs.frontBottomR1.getNode().x() + pos.x / that.scale > that.widthIn
                ? this.absolutePosition().x
                : pos.x,
            y: that.$refs.frontBottomL1.getNode().y() + pos.y / that.scale < 0
              ? - that.$refs.frontBottomL1.getNode().y() * that.scale
              : that.$refs.frontBottomL2.getNode().y() + pos.y / that.scale > that.heightIn
                ? this.absolutePosition().y
                : pos.y,
          }
        }
      }
    },
    getAngle(pointA = {}, pointB = {}, pointC = {}) {
      return function (pointA,pointB,pointC) {

        let A = pointA
        let B = pointB
        let C = pointC

        console.log('A',A)
        console.log('B',B)
        console.log('C',C)

        var AB = Math.sqrt(Math.pow(Math.abs(A.x - B.x), 2) + Math.pow(Math.abs(A.y - B.y), 2));
        console.log('AB',AB)
        var AC = Math.sqrt(Math.pow(A.x - C.x, 2) + Math.pow(A.y - C.y, 2));
        var BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
        var CB = Math.sqrt(Math.pow(Math.abs(C.x - B.x), 2) + Math.pow(Math.abs(C.y - B.y), 2));
        console.log('CB',CB)
        var cosA = (
                    Math.pow(AB, 2) + Math.pow(AC, 2) - Math.pow(BC, 2)
                  ) / (
                    2 * AB * AC
                  );
        var angleA = Math.round( Math.acos(cosA) * 180 / Math.PI );
        console.log('angleA',angleA)
      }
    },
    configFrontAngleBottomTool(){
      const that = this;
      return {
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          console.log('configFrontAngleBottomTool',pos)
          console.log(that.$refs.frontAngleBottomL2.getNode())

          const frontAngleBottomL1x = parseFloat(that.$refs.frontAngleBottomL1.getNode().x()) + parseFloat(pos.x)
          that.$refs.frontAngleBottomL1.getNode().setAttr('x',frontAngleBottomL1x)
          
          const frontAngleBottomL2x = parseFloat(that.$refs.frontAngleBottomL2.getNode().x()) + parseFloat(pos.x)
          that.$refs.frontAngleBottomL2.getNode().setAttr('x',frontAngleBottomL2x)

          const frontAngleBottomR1x = parseFloat(that.$refs.frontAngleBottomR1.getNode().x()) + parseFloat(pos.x)
          that.$refs.frontAngleBottomR1.getNode().setAttr('x',frontAngleBottomR1x)

          const frontAngleBottomL1y = parseFloat(that.$refs.frontAngleBottomL1.getNode().y()) + parseFloat(pos.y)
          that.$refs.frontAngleBottomL1.getNode().setAttr('y',frontAngleBottomL1y)

          const frontAngleBottomL2y = parseFloat(that.$refs.frontAngleBottomL2.getNode().y()) + parseFloat(pos.y)
          that.$refs.frontAngleBottomL2.getNode().setAttr('y',frontAngleBottomL2y)

          const frontAngleBottomR1y = parseFloat(that.$refs.frontAngleBottomR1.getNode().y()) + parseFloat(pos.y)
          that.$refs.frontAngleBottomR1.getNode().setAttr('y',frontAngleBottomR1y)

          return {
            x: that.$refs.frontAngleBottomL1.getNode().x() + pos.x / that.scale < 0
              ? - that.$refs.frontAngleBottomL1.getNode().x() * that.scale
              : that.$refs.frontAngleBottomR1.getNode().x() + pos.x / that.scale > that.widthIn
                ? this.absolutePosition().x
                : pos.x,
            y: that.$refs.frontAngleBottomL1.getNode().y() + pos.y / that.scale < 0
              ? - that.$refs.frontAngleBottomL1.getNode().y() * that.scale
              : that.$refs.frontAngleBottomL2.getNode().y() + pos.y / that.scale > that.heightIn
                ? this.absolutePosition().y
                : pos.y,
          }
        }
      }
    },
    //正位下骨骼工具-左上角
    frontAngleBottomL1 () {
      const that = this;
      return {
        x: 200,
        y: 200,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          console.log(pos)
          console.log(that.$refs.frontAngleBottomL1.getNode().y())
          const pointA = pos
          const pointB = {
            x: that.$refs.frontAngleBottomL2.getNode().x(),
            y: that.$refs.frontAngleBottomL2.getNode().y(),
          }
          const pointC = {
            x: that.$refs.frontAngleBottomR1.getNode().x(),
            y: that.$refs.frontAngleBottomR1.getNode().y(),
          }
          that.getAngle(pointA,pointB,pointC)
          return pos
        }
      }
    },
    //正位下骨骼工具-左上角
    frontAngleBottomL2 () {
      const that = this;
      return {
        x: 200,
        y: 500,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          console.log('1111',pos)
          console.log('22222',that.$refs.frontAngleBottomR1.getNode().y())
          const pointA = {
            x: that.$refs.frontAngleBottomL1.getNode().x(),
            y: that.$refs.frontAngleBottomL1.getNode().y(),
          }
          const pointB = {
            x: pos.x,
            y: pos.y
          }
          const pointC = {
            x: that.$refs.frontAngleBottomR1.getNode().x(),
            y: that.$refs.frontAngleBottomR1.getNode().y(),
          }

          that.getAngle(pointA,pointB,pointC)
          return pos
        }
      }
    },
    //正位下骨骼工具-左上角
    frontAngleBottomR1 () {
      const that = this;
      return {
        x: 500,
        y: 500,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          console.log(pos)
          const pointA = {
            x: that.$refs.frontAngleBottomL1.getNode().x(),
            y: that.$refs.frontAngleBottomL1.getNode().y(),
          }
          const pointB = {
            x: that.$refs.frontAngleBottomL2.getNode().x(),
            y: that.$refs.frontAngleBottomL2.getNode().y(),
          }
          const pointC = pos
          that.getAngle(pointA,pointB,pointC)
          return pos
        }
      }
    },
    //正位下骨骼工具-左上角
    frontBottomL1 () {
      return {
        x: this.oldWidth / 2 - 50,
        y: this.oldHeight / 2 + 100,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.frontBottomL1.x = pos.x - this.frontBottomTool.x() - this.stage.x();
          // this.frontBottomL1.y = pos.y - this.frontBottomTool.y() - this.stage.y();

          // this.frontBottomLine1.points[0] =  this.$refs.frontBottomL1.getNode().x();
          // this.frontBottomLine1.points[1] = this.$refs.frontBottomL1.getNode().y();
          this.$refs.frontBottomLine1.getNode().points(
            [
              pos.x - this.frontBottomTool.x(),
              pos.y - this.frontBottomTool.y(),
              this.$refs.frontBottomLine1.getNode().points()[2],
              this.$refs.frontBottomLine1.getNode().points()[3],


            ]
          )
          // this.frontBottomLineCenter.points[2] = (this.$refs.frontBottomL1.getNode().x() + this.$refs.frontBottomR1.getNode().x()) / 2;
          // this.frontBottomLineCenter.points[3] = (this.$refs.frontBottomL1.getNode().y() + this.$refs.frontBottomR1.getNode().y()) / 2;
          this.setFrontBottomPoint();
          setTimeout(this.frontDataChange, 10)
          let points = this.$refs.frontBottomLineCenter.getNode().points();
          let frontSection = this.$refs.frontSection.getNode()
          frontSection.x(this.getPointX(points[2], points[3], points[4], points[5], frontSection.y()))
          return pos;
        }
      }
    },
    //正位下骨骼工具-右上角
    frontBottomR1 () {
      return {
        x: this.oldWidth / 2 + 50,
        y: this.oldHeight / 2 + 100,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.frontBottomR1.x = pos.x - this.frontBottomTool.x() - this.stage.x();
          // this.frontBottomR1.y = pos.y - this.frontBottomTool.y() - this.stage.y();
          // this.frontBottomLine1.points[2] = this.$refs.frontBottomR1.getNode().x();
          // this.frontBottomLine1.points[3] = this.$refs.frontBottomR1.getNode().y();
          this.$refs.frontBottomLine1.getNode().points(
            [
              this.$refs.frontBottomLine1.getNode().points()[0],
              this.$refs.frontBottomLine1.getNode().points()[1],
              pos.x - this.frontBottomTool.x(),
              pos.y - this.frontBottomTool.y(),

            ]
          )
          // this.frontBottomLineCenter.points[2] = (this.$refs.frontBottomL1.getNode().x() + this.$refs.frontBottomR1.getNode().x()) / 2;
          // this.frontBottomLineCenter.points[3] = (this.$refs.frontBottomL1.getNode().y() + this.$refs.frontBottomR1.getNode().y()) / 2;
          this.setFrontBottomPoint();
          setTimeout(this.frontDataChange, 10)
          let points = this.$refs.frontBottomLineCenter.getNode().points();
          let frontSection = this.$refs.frontSection.getNode()
          frontSection.x(this.getPointX(points[2], points[3], points[4], points[5], frontSection.y()))
          return pos;
        }
      }
    },
    //正位下骨骼工具-左下角
    frontBottomL2 () {
      return {
        x: this.oldWidth / 2 - 50,
        y: this.oldHeight / 2 + 150,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.frontBottomL2.x = pos.x - this.frontBottomTool.x() - this.stage.x();
          // this.frontBottomL2.y = pos.y - this.frontBottomTool.y() - this.stage.y();
          // this.frontBottomLine2.points[0] = this.$refs.frontBottomL2.getNode().x();
          // this.frontBottomLine2.points[1] = this.$refs.frontBottomL2.getNode().y();
          this.$refs.frontBottomLine2.getNode().points(
            [
              pos.x - this.frontBottomTool.x(),
              pos.y - this.frontBottomTool.y(),
              this.$refs.frontBottomLine2.getNode().points()[2],
              this.$refs.frontBottomLine2.getNode().points()[3],
            ]
          )
          // this.frontBottomLineCenter.points[4] = (this.$refs.frontBottomL2.getNode().x() + this.$refs.frontBottomR2.getNode().x()) / 2;
          // this.frontBottomLineCenter.points[5] = (this.$refs.frontBottomL2.getNode().y() + this.$refs.frontBottomR2.getNode().y()) / 2;
          this.setFrontBottomPoint();
          setTimeout(this.frontDataChange, 10)
          let points = this.$refs.frontBottomLineCenter.getNode().points();
          let frontSection = this.$refs.frontSection.getNode()
          frontSection.x(this.getPointX(points[2], points[3], points[4], points[5], frontSection.y()))
          return pos;
        }
      }
    },
    //正位下骨骼工具-右下角
    frontBottomR2 () {
      return {
        x: this.oldWidth / 2 + 50,
        y: this.oldHeight / 2 + 150,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.frontBottomR2.x = pos.x - this.frontBottomTool.x() - this.stage.x();
          // this.frontBottomR2.y = pos.y - this.frontBottomTool.y() - this.stage.y();
          // this.frontBottomLine2.points[2] = this.$refs.frontBottomR2.getNode().x();
          // this.frontBottomLine2.points[3] = this.$refs.frontBottomR2.getNode().y();
          this.$refs.frontBottomLine2.getNode().points(
            [
              this.$refs.frontBottomLine2.getNode().points()[0],
              this.$refs.frontBottomLine2.getNode().points()[1],
              pos.x - this.frontBottomTool.x(),
              pos.y - this.frontBottomTool.y(),
            ]
          )
          // this.frontBottomLineCenter.points[4] = (this.$refs.frontBottomL2.getNode().x() + this.$refs.frontBottomR2.getNode().x()) / 2;
          // this.frontBottomLineCenter.points[5] = (this.$refs.frontBottomL2.getNode().y() + this.$refs.frontBottomR2.getNode().y()) / 2;
          this.setFrontBottomPoint();
          setTimeout(this.frontDataChange, 10)
          let points = this.$refs.frontBottomLineCenter.getNode().points();
          let frontSection = this.$refs.frontSection.getNode()
          frontSection.x(this.getPointX(points[2], points[3], points[4], points[5], frontSection.y()))
          return pos;
        }
      }
    },
    //正位下骨骼工具-顶边
    frontBottomLine1 () {
      return {
        points: [
          this.$refs.frontBottomL1 ? this.$refs.frontBottomL1.getNode().x() : this.frontBottomL1.x,
          this.$refs.frontBottomL1 ? this.$refs.frontBottomL1.getNode().y() : this.frontBottomL1.y,
          this.$refs.frontBottomR1 ? this.$refs.frontBottomR1.getNode().x() : this.frontBottomR1.x,
          this.$refs.frontBottomR1 ? this.$refs.frontBottomR1.getNode().y() : this.frontBottomR1.y,
        ],
        stroke: '#FFFF00',
        strokeWidth: 4 / this.scale,

      }
    },
    //正位下骨骼工具-底边
    frontAngleBottomLine2 () {
      return {
        points: [
          this.$refs.frontAngleBottomL1 ? this.$refs.frontAngleBottomL1.getNode().x() : this.frontAngleBottomL1.x,
          this.$refs.frontAngleBottomL1 ? this.$refs.frontAngleBottomL1.getNode().y() : this.frontAngleBottomL1.y,
          this.$refs.frontAngleBottomL2 ? this.$refs.frontAngleBottomL2.getNode().x() : this.frontAngleBottomL2.x,
          this.$refs.frontAngleBottomL2 ? this.$refs.frontAngleBottomL2.getNode().y() : this.frontAngleBottomL2.y,
        ],
        stroke: '#FFFF00',
        strokeWidth: 4 / this.scale,
      }
    },

    //正位下骨骼工具-顶边
    frontAngleBottomLine1 () {
      return {
        points: [
          this.$refs.frontAngleBottomL2 ? this.$refs.frontAngleBottomL2.getNode().x() : this.frontAngleBottomL2.x,
          this.$refs.frontAngleBottomL2 ? this.$refs.frontAngleBottomL2.getNode().y() : this.frontAngleBottomL2.y,
          this.$refs.frontAngleBottomR1 ? this.$refs.frontAngleBottomR1.getNode().x() : this.frontAngleBottomR1.x,
          this.$refs.frontAngleBottomR1 ? this.$refs.frontAngleBottomR1.getNode().y() : this.frontAngleBottomR1.y,
        ],
        stroke: '#FFFF00',
        strokeWidth: 4 / this.scale,

      }
    },
    //正位下骨骼工具-底边
    frontBottomLine2 () {
      return {
        points: [
          this.$refs.frontBottomL2 ? this.$refs.frontBottomL2.getNode().x() : this.frontBottomL2.x,
          this.$refs.frontBottomL2 ? this.$refs.frontBottomL2.getNode().y() : this.frontBottomL2.y,
          this.$refs.frontBottomR2 ? this.$refs.frontBottomR2.getNode().x() : this.frontBottomR2.x,
          this.$refs.frontBottomR2 ? this.$refs.frontBottomR2.getNode().y() : this.frontBottomR2.y,
        ],
        stroke: '#FFFF00',
        strokeWidth: 4 / this.scale,
      }
    },
    //正位下骨骼工具-中线
    frontBottomLineCenter () {

      return {
        points: this.getPoint(
          this.$refs.frontBottomL1 ? this.$refs.frontBottomL1.getNode().x() : this.frontBottomL1.x,
          this.$refs.frontBottomL1 ? this.$refs.frontBottomL1.getNode().y() : this.frontBottomL1.y,
          this.$refs.frontBottomR1 ? this.$refs.frontBottomR1.getNode().x() : this.frontBottomR1.x,
          this.$refs.frontBottomR1 ? this.$refs.frontBottomR1.getNode().y() : this.frontBottomR1.y,
          this.$refs.frontBottomL2 ? this.$refs.frontBottomL2.getNode().x() : this.frontBottomL2.x,
          this.$refs.frontBottomL2 ? this.$refs.frontBottomL2.getNode().y() : this.frontBottomL2.y,
          this.$refs.frontBottomR2 ? this.$refs.frontBottomR2.getNode().x() : this.frontBottomR2.x,
          this.$refs.frontBottomR2 ? this.$refs.frontBottomR2.getNode().y() : this.frontBottomR2.y,
        ),
        stroke: '#FFFF00',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [10 / this.scale, 4 / this.scale],
      }
    },
    //正位下骨骼工具-下截面点
    frontSection () {
      let points = this.$refs.frontBottomLineCenter ? this.$refs.frontBottomLineCenter.getNode().points() : this.frontBottomLineCenter.points;
      let pointY = this.$refs.frontSection ? this.$refs.frontSection.getNode().y() : points[3] - 10;
      return {
        x: this.getPointX(
          this.frontBottomLineCenter.points[2],
          this.frontBottomLineCenter.points[3],
          this.frontBottomLineCenter.points[4],
          this.frontBottomLineCenter.points[5],
          pointY
        ),
        y: pointY,
        radius: 4 / this.scale,
        fill: '#8000FF',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          setTimeout(this.frontDataChange, 10)
          // let points = this.$refs.frontBottomLineCenter.getNode().points();
          pos = {
            x: this.getPointX(
              points[2] + this.frontBottomTool.x() + this.stage.x(),
              points[3] + this.frontBottomTool.y() + this.stage.y(),
              points[4] + this.frontBottomTool.x() + this.stage.x(),
              points[5] + this.frontBottomTool.y() + this.stage.y(),
              pos.y / this.scale
            ) * this.scale,
            y: pos.y
          }
          return pos;
        }
      }
    },
    //侧位上骨骼工具
    configSideTopTool () {
      const that = this;
      return {
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          setTimeout(that.sideDataChange, 10);
          return {
            x: that.$refs.sideTopL1.getNode().x() + pos.x / that.scale < 0
              ? - that.$refs.sideTopL1.getNode().x() * that.scale
              : that.$refs.sideTopR1.getNode().x() + pos.x / that.scale > that.widthIn2
                ? this.absolutePosition().x
                : pos.x,
            y: that.$refs.sideTopL1.getNode().y() + pos.y / that.scale < 0
              ? - that.$refs.sideTopL1.getNode().y() * that.scale
              : that.$refs.sideTopL2.getNode().y() + pos.y / that.scale > that.heightIn2
                ? this.absolutePosition().y
                : pos.y,
          }
        }
      }
    },
    //侧位上骨骼工具-左上角
    sideTopL1 () {
      return {
        x: this.oldWidth / 2 - 50,
        y: this.oldHeight / 2 - 150,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {

          // this.sideTopL1.x = pos.x - this.sideTopTool.x() - this.stage2.x();
          // this.sideTopL1.y = pos.y - this.sideTopTool.y() - this.stage2.y();
          // this.sideTopLine1.points[0] =  this.$refs.sideTopL1.getNode().x();
          // this.sideTopLine1.points[1] = this.$refs.sideTopL1.getNode().y();
          this.$refs.sideTopLine1.getNode().points(
            [
              pos.x - this.sideTopTool.x(),
              pos.y - this.sideTopTool.y(),
              this.$refs.sideTopLine1.getNode().points()[2],
              this.$refs.sideTopLine1.getNode().points()[3],
            ]
          )
          // this.sideTopLineCenter.points[2] = (this.$refs.sideTopL1.getNode().x() + this.$refs.sideTopR1.getNode().x()) / 2;
          // this.sideTopLineCenter.points[3] = (this.$refs.sideTopL1.getNode().y() + this.$refs.sideTopR1.getNode().y()) / 2;
          this.setSideTopPoint();
          setTimeout(this.sideDataChange, 10);
          return pos;
        }
      }
    },
    //侧位上骨骼工具-右上角
    sideTopR1 () {
      return {
        x: this.oldWidth / 2 + 50,
        y: this.oldHeight / 2 - 150,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.sideTopR1.x = pos.x - this.sideTopTool.x() - this.stage2.x();
          // this.sideTopR1.y = pos.y - this.sideTopTool.y() - this.stage2.y();
          // this.sideTopLine1.points[2] = this.$refs.sideTopR1.getNode().x();
          // this.sideTopLine1.points[3] = this.$refs.sideTopR1.getNode().y();
          this.$refs.sideTopLine1.getNode().points(
            [
              this.$refs.sideTopLine1.getNode().points()[0],
              this.$refs.sideTopLine1.getNode().points()[1],
              pos.x - this.sideTopTool.x(),
              pos.y - this.sideTopTool.y(),
            ]
          )
          // this.sideTopLineCenter.points[2] = (this.$refs.sideTopL1.getNode().x() + this.$refs.sideTopR1.getNode().x()) / 2;
          // this.sideTopLineCenter.points[3] = (this.$refs.sideTopL1.getNode().y() + this.$refs.sideTopR1.getNode().y()) / 2;
          this.setSideTopPoint();
          setTimeout(this.sideDataChange, 10);
          return pos;
        }
      }
    },
    //侧位上骨骼工具-左下角
    sideTopL2 () {
      return {
        x: this.oldWidth / 2 - 50,
        y: this.oldHeight / 2 - 100,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.sideTopL2.x = pos.x - this.sideTopTool.x() - this.stage2.x();
          // this.sideTopL2.y = pos.y - this.sideTopTool.y() - this.stage2.y();
          // this.sideTopLine2.points[0] = this.$refs.sideTopL2.getNode().x();
          // this.sideTopLine2.points[1] = this.$refs.sideTopL2.getNode().y();
          this.$refs.sideTopLine2.getNode().points(
            [
              pos.x - this.sideTopTool.x(),
              pos.y - this.sideTopTool.y(),
              this.$refs.sideTopLine2.getNode().points()[2],
              this.$refs.sideTopLine2.getNode().points()[3],
            ]
          )
          // this.sideTopLineCenter.points[4] = (this.$refs.sideTopL2.getNode().x() + this.$refs.sideTopR2.getNode().x()) / 2;
          // this.sideTopLineCenter.points[5] = (this.$refs.sideTopL2.getNode().y() + this.$refs.sideTopR2.getNode().y()) / 2;
          this.setSideTopPoint();
          setTimeout(this.sideDataChange, 10);
          return pos;
        }
      }
    },
    //侧位上骨骼工具-右下角
    sideTopR2 () {
      return {
        x: this.oldWidth / 2 + 50,
        y: this.oldHeight / 2 - 100,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          // this.sideTopR2.x = pos.x - this.sideTopTool.x() - this.stage2.x();
          // this.sideTopR2.y = pos.y - this.sideTopTool.y() - this.stage2.y();
          // this.sideTopLine2.points[2] = this.$refs.sideTopR2.getNode().x();
          // this.sideTopLine2.points[3] = this.$refs.sideTopR2.getNode().y();
          this.$refs.sideTopLine2.getNode().points(
            [
              this.$refs.sideTopLine2.getNode().points()[0],
              this.$refs.sideTopLine2.getNode().points()[1],
              pos.x - this.sideTopTool.x(),
              pos.y - this.sideTopTool.y(),
            ]
          )
          // this.sideTopLineCenter.points[4] = (this.$refs.sideTopL2.getNode().x() + this.$refs.sideTopR2.getNode().x()) / 2;
          // this.sideTopLineCenter.points[5] = (this.$refs.sideTopL2.getNode().y() + this.$refs.sideTopR2.getNode().y()) / 2;
          this.setSideTopPoint();
          setTimeout(this.sideDataChange, 10);
          return pos;
        }
      }
    },
    //侧位上骨骼工具-顶边
    sideTopLine1 () {
      return {
        points: [
          this.$refs.sideTopL1 ? this.$refs.sideTopL1.getNode().x() : this.sideTopL1.x,
          this.$refs.sideTopL1 ? this.$refs.sideTopL1.getNode().y() : this.sideTopL1.y,
          this.$refs.sideTopR1 ? this.$refs.sideTopR1.getNode().x() : this.sideTopR1.x,
          this.$refs.sideTopR1 ? this.$refs.sideTopR1.getNode().y() : this.sideTopR1.y,
        ],
        stroke: '#00BF99',
        strokeWidth: 4 / this.scale,

      }
    },
    //侧上骨骼工具-底边
    sideTopLine2 () {
      return {
        points: [
          this.$refs.sideTopL2 ? this.$refs.sideTopL2.getNode().x() : this.sideTopL2.x,
          this.$refs.sideTopL2 ? this.$refs.sideTopL2.getNode().y() : this.sideTopL2.y,
          this.$refs.sideTopR2 ? this.$refs.sideTopR2.getNode().x() : this.sideTopR2.x,
          this.$refs.sideTopR2 ? this.$refs.sideTopR2.getNode().y() : this.sideTopR2.y,
        ],
        stroke: '#00BF99',
        strokeWidth: 4 / this.scale,
      }
    },
    //侧位上骨骼工具-中线
    sideTopLineCenter () {
      return {
        points: this.getPoint(
          this.$refs.sideTopL1 ? this.$refs.sideTopL1.getNode().x() : this.sideTopL1.x,
          this.$refs.sideTopL1 ? this.$refs.sideTopL1.getNode().y() : this.sideTopL1.y,
          this.$refs.sideTopR1 ? this.$refs.sideTopR1.getNode().x() : this.sideTopR1.x,
          this.$refs.sideTopR1 ? this.$refs.sideTopR1.getNode().y() : this.sideTopR1.y,
          this.$refs.sideTopL2 ? this.$refs.sideTopL2.getNode().x() : this.sideTopL2.x,
          this.$refs.sideTopL2 ? this.$refs.sideTopL2.getNode().y() : this.sideTopL2.y,
          this.$refs.sideTopR2 ? this.$refs.sideTopR2.getNode().x() : this.sideTopR2.x,
          this.$refs.sideTopR2 ? this.$refs.sideTopR2.getNode().y() : this.sideTopR2.y,
        ),
        stroke: '#00BF99',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [10 / this.scale, 4 / this.scale],
      }
    },

    //侧位下骨骼工具
    configSideBottomTool () {
      const that = this;
      return {
        draggable: !this.lock,
        dragBoundFunc: function (pos) {
          setTimeout(that.sideDataChange, 10);
          return {
            x: that.$refs.sideBottomL1.getNode().x() + pos.x / that.scale < 0
              ? - that.$refs.sideBottomL1.getNode().x() * that.scale
              : that.$refs.sideBottomR1.getNode().x() + pos.x / that.scale > that.widthIn2
                ? this.absolutePosition().x
                : pos.x,
            y: that.$refs.sideBottomL1.getNode().y() + pos.y / that.scale < 0
              ? - that.$refs.sideBottomL1.getNode().y() * that.scale
              : that.$refs.sideBottomL2.getNode().y() + pos.y / that.scale > that.heightIn2
                ? this.absolutePosition().y
                : pos.y,
          }
        }
      }
    },
    //侧位下骨骼工具-左上角
    sideBottomL1 () {
      return {
        x: this.oldWidth / 2 - 50,
        y: this.oldHeight / 2 + 100,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          setTimeout(this.sideDataChange, 10);
          this.$refs.sideBottomLine1.getNode().points(
            [
              pos.x - this.sideBottomTool.x(),
              pos.y - this.sideBottomTool.y(),
              this.$refs.sideBottomLine1.getNode().points()[2],
              this.$refs.sideBottomLine1.getNode().points()[3],
            ]
          )
          this.setSideBottomPoint();
          this.reSection('side')
          return pos;
        }
      }
    },
    //侧位下骨骼工具-右上角
    sideBottomR1 () {
      return {
        x: this.oldWidth / 2 + 50,
        y: this.oldHeight / 2 + 100,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          this.$refs.sideBottomLine1.getNode().points(
            [
              this.$refs.sideBottomLine1.getNode().points()[0],
              this.$refs.sideBottomLine1.getNode().points()[1],
              pos.x - this.sideBottomTool.x(),
              pos.y - this.sideBottomTool.y(),
            ]
          )
          this.setSideBottomPoint();
          setTimeout(this.sideDataChange, 10);
          this.reSection('side')
          return pos;
        }
      }
    },
    //侧位下骨骼工具-左下角
    sideBottomL2 () {
      return {
        x: this.oldWidth / 2 - 50,
        y: this.oldHeight / 2 + 150,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          this.$refs.sideBottomLine2.getNode().points(
            [
              pos.x - this.sideBottomTool.x(),
              pos.y - this.sideBottomTool.y(),
              this.$refs.sideBottomLine2.getNode().points()[2],
              this.$refs.sideBottomLine2.getNode().points()[3],

            ]
          )
          this.setSideBottomPoint();
          setTimeout(this.sideDataChange, 10);
          this.reSection('side')
          return pos;
        }
      }
    },
    //侧位下骨骼工具-右下角
    sideBottomR2 () {
      return {
        x: this.oldWidth / 2 + 50,
        y: this.oldHeight / 2 + 150,
        radius: 3 / this.scale,
        fill: '#D8001B',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          this.$refs.sideBottomLine2.getNode().points(
            [
              this.$refs.sideBottomLine2.getNode().points()[0],
              this.$refs.sideBottomLine2.getNode().points()[1],
              pos.x - this.sideBottomTool.x(),
              pos.y - this.sideBottomTool.y()
            ]
          )
          this.setSideBottomPoint();
          setTimeout(this.sideDataChange, 10);
          this.reSection('side')
          return pos;
        }
      }
    },
    //侧位下骨骼工具-顶边
    sideBottomLine1 () {
      return {
        points: [
          this.$refs.sideBottomL1 ? this.$refs.sideBottomL1.getNode().x() : this.sideBottomL1.x,
          this.$refs.sideBottomL1 ? this.$refs.sideBottomL1.getNode().y() : this.sideBottomL1.y,
          this.$refs.sideBottomR1 ? this.$refs.sideBottomR1.getNode().x() : this.sideBottomR1.x,
          this.$refs.sideBottomR1 ? this.$refs.sideBottomR1.getNode().y() : this.sideBottomR1.y,
        ],
        stroke: '#FFFF00',
        strokeWidth: 4 / this.scale,
      }
    },
    //侧位下骨骼工具-底边
    sideBottomLine2 () {
      return {
        points: [
          this.$refs.sideBottomL2 ? this.$refs.sideBottomL2.getNode().x() : this.sideBottomL2.x,
          this.$refs.sideBottomL2 ? this.$refs.sideBottomL2.getNode().y() : this.sideBottomL2.y,
          this.$refs.sideBottomR2 ? this.$refs.sideBottomR2.getNode().x() : this.sideBottomR2.x,
          this.$refs.sideBottomR2 ? this.$refs.sideBottomR2.getNode().y() : this.sideBottomR2.y,
        ],
        stroke: '#FFFF00',
        strokeWidth: 4 / this.scale,
      }
    },
    //侧位下骨骼工具-中线
    sideBottomLineCenter () {
      return {
        points: this.getPoint(
          this.$refs.sideBottomL1 ? this.$refs.sideBottomL1.getNode().x() : this.sideBottomL1.x,
          this.$refs.sideBottomL1 ? this.$refs.sideBottomL1.getNode().y() : this.sideBottomL1.y,
          this.$refs.sideBottomR1 ? this.$refs.sideBottomR1.getNode().x() : this.sideBottomR1.x,
          this.$refs.sideBottomR1 ? this.$refs.sideBottomR1.getNode().y() : this.sideBottomR1.y,
          this.$refs.sideBottomL2 ? this.$refs.sideBottomL2.getNode().x() : this.sideBottomL2.x,
          this.$refs.sideBottomL2 ? this.$refs.sideBottomL2.getNode().y() : this.sideBottomL2.y,
          this.$refs.sideBottomR2 ? this.$refs.sideBottomR2.getNode().x() : this.sideBottomR2.x,
          this.$refs.sideBottomR2 ? this.$refs.sideBottomR2.getNode().y() : this.sideBottomR2.y,
        ),
        stroke: '#FFFF00',
        strokeWidth: 2 / this.scale,
        lineJoin: 'round',
        dash: [10 / this.scale, 4 / this.scale],
      }
    },
    //侧位下骨骼工具-下截面点
    sideSection () {
      let points = this.$refs.sideBottomLineCenter ? this.$refs.sideBottomLineCenter.getNode().points() : this.sideBottomLineCenter.points;
      let pointY = this.$refs.sideSection ? this.$refs.sideSection.getNode().y() : points[3] - 10;
      return {
        x: this.getPointX(
          points[2],
          points[3],
          points[4],
          points[5],
          pointY
        ),
        y: pointY,
        radius: 4 / this.scale,
        fill: '#8000FF',
        draggable: !this.lock,
        dragBoundFunc: (pos) => {
          setTimeout(this.sideDataChange, 10)
          // let points = this.$refs.sideBottomLineCenter.getNode().points();
          pos = {
            x: this.getPointX(
              points[2] + this.sideBottomTool.x() + this.stage2.x(),
              points[3] + this.sideBottomTool.y() + this.stage2.y(),
              points[4] + this.sideBottomTool.x() + this.stage2.x(),
              points[5] + this.sideBottomTool.y() + this.stage2.y(),
              pos.y / this.scale
            ) * this.scale,
            y: pos.y
          }
          return pos;
        }
      }
    },
  },

  created () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.$api.getReport({
        before_after: 1,
        id: this.id
      }).then(res => {
        if (res.code) {
          const install = res.data.install;
          if (install) {
            this.reference = Number(install.far_near);
          }
          this.slideText = res.data.case.slide_text;
          this.slide = res.data.case.slide;
          this.base = res.data.base;
          this.frontSlide = this.base[0].left_right ? JSON.parse(this.base[0].left_right).slide : this.slide;
          this.sideSlide = this.base[1].left_right ? JSON.parse(this.base[1].left_right).slide : this.slide;

          // this.dist = this.base[0].proportion * this.actual; 

          // this.dist =  this.base[0].point ? JSON.parse(this.base[0].point).y : 0; 
          // this.actual = this.dist ? (this.dist / this.base[0].proportion).toFixed(2) : 0;

          this.countActual(this.dist);

          this.frontImageObj.src = this.$baseUrl + this.base[0].image;
          this.sideImageObj.src = this.$baseUrl + this.base[1].image;
          this.setCoordinates();
          this.frontDataChange();
          this.sideDataChange();
          this.ruleForm.front.angle = this.base[0].flip_angle ? this.base[0].flip_angle : 0;
          this.ruleForm.front.distance = this.base[0].slide_dis ? this.base[0].slide_dis : 0;
          this.ruleForm.front.angleDir = this.base[0].flip ? this.base[0].flip : 0;
          this.ruleForm.front.distanceDir = this.base[0].slide ? this.base[0].slide : 0;

          this.ruleForm.side.angle = this.base[1].flip_angle ? this.base[1].flip_angle : 0;
          this.ruleForm.side.distance = this.base[1].slide_dis ? this.base[1].slide_dis : 0;
          this.ruleForm.side.angleDir = this.base[1].flip ? this.base[1].flip : 0;
          this.ruleForm.side.distanceDir = this.base[1].slide ? this.base[1].slide : 0;

          this.ruleForm.axis.angle = this.base[2] && this.base[2].flip_angle ? this.base[2].flip_angle : 0;
          this.ruleForm.axis.distance = this.base[2] && this.base[2].slide_dis ? this.base[2].slide_dis : 0;
          this.ruleForm.axis.angleDir = this.base[2] && this.base[2].flip ? this.base[2].flip : "0";
          this.ruleForm.axis.distanceDir = this.base[2] && this.base[2].slide ? this.base[2].slide : "0";

        }
      })
    } else {
      this.$message.error(this.$t('common.errMsg2'));
      setTimeout(res => {
        this.$router.go(-1)
      }, 500)
    }

  },
  mounted () {
    document.getElementsByClassName('right-side')[0].style.height = this.configKonva.height + 'px';
    document.getElementsByClassName('left-side')[0].style.height = this.configKonva.height + 'px';
    this.stage = this.$refs.stage.getNode();
    this.layer = this.$refs.layer.getNode();
    this.stage2 = this.$refs.stage2.getNode();
    this.layer2 = this.$refs.layer2.getNode();
    this.xRay = this.$refs.xFront.getNode();
    this.xRayS = this.$refs.xSide.getNode();
    this.frontTopTool = this.$refs.frontTopGroup.getNode();
    this.frontBottomTool = this.$refs.frontBottomGroup.getNode();
    this.sideTopTool = this.$refs.sideTopGroup.getNode();
    this.sideBottomTool = this.$refs.sideBottomGroup.getNode();
    let lineReference1 = this.$refs.lineReference1.getNode();
    let lineReference2 = this.$refs.lineReference2.getNode();
    let that = this;
    //监听事件改变鼠标样式
    lineReference1.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
    });
    lineReference1.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
    });
    lineReference2.on('mouseenter', () => {
      this.stage2.container().style.cursor = 'pointer';
    });
    lineReference2.on('mouseleave', () => {
      this.stage2.container().style.cursor = 'default';
    });
    this.frontTopTool.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
    });
    this.frontTopTool.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
    });
    this.frontBottomTool.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
    });
    this.frontBottomTool.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
    });
    this.sideTopTool.on('mouseenter', () => {
      this.stage2.container().style.cursor = 'pointer';
    });
    this.sideTopTool.on('mouseleave', () => {
      this.stage2.container().style.cursor = 'default';
    });
    this.sideBottomTool.on('mouseenter', () => {
      this.stage2.container().style.cursor = 'pointer';
    });
    this.sideBottomTool.on('mouseleave', () => {
      this.stage2.container().style.cursor = 'default';
    });

    this.frontImageObj.onload = function (e) {
      // that.resizeImg(this);
      that.xRay.rotation(that.base[0].angle)
      that.heightIn = that.xRay.height();
      that.widthIn = that.xRay.width();

    }
    this.sideImageObj.onload = function (e) {
      // that.resizeImg(this);
      // setTimeout(that.zoom, 10)
      that.unifyImg(this);
      that.xRayS.rotation(that.base[1].angle)
      that.heightIn2 = that.xRayS.height();
      that.widthIn2 = that.xRayS.width();
      that.xRayS.y(that.xRayS.y() + Number(that.base[1].move))
      that.layer2.draw();
    }

  },
  methods: {
    //绘制距离线
    drawDistLine (y) {
      let level = this.base.length && this.base[0].point ? JSON.parse(this.base[0].point).y : 0;
      this.$refs.distanceList1.getNode().points([this.widthIn - 50, level, this.widthIn - 50, y])
      this.$refs.distanceList2.getNode().points([this.widthIn - 50, level, this.widthIn - 50, y])
    },
    //计算参考线距离
    countActual (dist) {
      this.actual = this.base[0].point ? Math.abs(((JSON.parse(this.base[0].point).y - dist) / this.base[0].proportion).toFixed(2)) : 0;
    },
    //同比比例
    unifyImg (image) {
      let proportion1 = this.base[0].proportion;
      let proportion2 = this.base[1].proportion;
      let unify = proportion1 / proportion2;
      image.height = image.height * unify;
      image.width = image.width * unify;
      this.isUnify = true;
      return image;
    },
    //正位患侧信息修改
    frontSlideChange () {
      this.frontSlide = this.frontSlide == '0' ? '1' : '0';
      this.saveSlide();
    },
    //侧位患侧信息修改
    sideSlideChange () {
      this.sideSlide = this.sideSlide == '0' ? '1' : '0';
      this.saveSlide();
    },
    //保存患侧信息
    saveSlide () {
      this.$api.saveLeftRight({
        id: this.id,
        before_after: 1,
        left_right: JSON.stringify([{ slide: this.frontSlide }, { slide: this.sideSlide }])
      })
    },
    //设置正位上工具中线坐标
    setFrontTopPoint () {
      let point = this.getPoint(
        this.$refs.frontTopL1.getNode().x(),
        this.$refs.frontTopL1.getNode().y(),
        this.$refs.frontTopR1.getNode().x(),
        this.$refs.frontTopR1.getNode().y(),
        this.$refs.frontTopL2.getNode().x(),
        this.$refs.frontTopL2.getNode().y(),
        this.$refs.frontTopR2.getNode().x(),
        this.$refs.frontTopR2.getNode().y(),
      );
      this.$refs.frontTopLineCenter.getNode().points(point)
    },
    //设置正位下工具中线坐标
    setFrontBottomPoint () {
      let point = this.getPoint(
        this.$refs.frontBottomL1.getNode().x(),
        this.$refs.frontBottomL1.getNode().y(),
        this.$refs.frontBottomR1.getNode().x(),
        this.$refs.frontBottomR1.getNode().y(),
        this.$refs.frontBottomL2.getNode().x(),
        this.$refs.frontBottomL2.getNode().y(),
        this.$refs.frontBottomR2.getNode().x(),
        this.$refs.frontBottomR2.getNode().y(),
      );
      this.$refs.frontBottomLineCenter.getNode().points(point)
    },
    //设置侧位上工具中线坐标
    setSideTopPoint () {
      let point = this.getPoint(
        this.$refs.sideTopL1.getNode().x(),
        this.$refs.sideTopL1.getNode().y(),
        this.$refs.sideTopR1.getNode().x(),
        this.$refs.sideTopR1.getNode().y(),
        this.$refs.sideTopL2.getNode().x(),
        this.$refs.sideTopL2.getNode().y(),
        this.$refs.sideTopR2.getNode().x(),
        this.$refs.sideTopR2.getNode().y(),
      );
      this.$refs.sideTopLineCenter.getNode().points(point)
    },
    //设置侧位下工具中线坐标
    setSideBottomPoint () {
      let point = this.getPoint(
        this.$refs.sideBottomL1.getNode().x(),
        this.$refs.sideBottomL1.getNode().y(),
        this.$refs.sideBottomR1.getNode().x(),
        this.$refs.sideBottomR1.getNode().y(),
        this.$refs.sideBottomL2.getNode().x(),
        this.$refs.sideBottomL2.getNode().y(),
        this.$refs.sideBottomR2.getNode().x(),
        this.$refs.sideBottomR2.getNode().y(),
      );
      this.$refs.sideBottomLineCenter.getNode().points(point)
    },
    //获取中线坐标
    getPoint (x1, y1, x2, y2, x3, y3, x4, y4) {
      let topCenterX = (x1 + x2) / 2;
      let topCenterY = (y1 + y2) / 2;
      let bottomCenterX = (x3 + x4) / 2;
      let bottomCenterY = (y3 + y4) / 2;
      return [
        this.getPointX(topCenterX, topCenterY, bottomCenterX, bottomCenterY, -10000),
        -10000,
        topCenterX,
        topCenterY,
        bottomCenterX,
        bottomCenterY,
        this.getPointX(topCenterX, topCenterY, bottomCenterX, bottomCenterY, this.heightIn + 10000),
        this.heightIn + 10000,
      ]
    },
    getPointX (x1, y1, x2, y2, y = 0) {
      return (x2 - x1) / (y2 - y1) * (y - y1) + x1;
    },
    //画布放大
    onIncrease () {
      this.scale = this.scale / 0.9;
      this.zoom();
    },
    //恢复原尺寸
    onReset () {
      this.scale = 1;
      this.zoom();
    },
    //画布缩小
    onReduce () {
      this.scale = this.scale * 0.9;
      this.zoom();
    },
    //工具复位
    onRestore () {
      this.reload()
    },

    onAngleMeasure(){

    },

    zoom () {
      this.stage.width(this.widthIn * this.scale)
      this.stage.height(this.heightIn * this.scale)
      this.stage.scale({ x: this.scale, y: this.scale })
      this.stage2.width(this.widthIn2 * this.scale)
      this.stage2.height(this.heightIn2 * this.scale)
      this.stage2.scale({ x: this.scale, y: this.scale })
      this.layer.draw();
      this.layer2.draw();

    },
    resizeImg (image) {
      let w = image.width; //图片宽度
      let h = image.height; //图片宽度
      let FitWidth = this.configKonva.width //画布宽度
      let FitHeight = this.configKonva.height //画布宽度
      // 当图片比预览区域小时不做任何改变
      if (w < FitWidth && h < FitHeight) {
        return false;
      }

      // 当实际图片比例大于预览区域宽高比例时
      if (image.width / image.height >= FitWidth / FitHeight) {
        //宽度超出 
        image.height = ((image.height * FitWidth) / image.width) / 2;
        image.width = FitWidth / 2;
      } else {
        //高度超出
        image.width = ((image.width * FitHeight) / image.height) / 2;
        image.height = FitHeight / 2;
      }
    },

    //获取移动后的线段坐标
    getLastPoints (points, tool) {
      const lastPoints = [];
      points.forEach((v, k) => {
        lastPoints.push(k % 2 == 0 ? v + tool.x() : v + tool.y());
      });
      return lastPoints;
    },

    //提交，下一步
    async submit () {
      if (this.disabled) return false;
      this.dialogVisible = false;
      this.disabled = true;
      if (this.ruleForm.axis.angle === '') {
        this.disabled = false;
        return this.$message.error('请输入轴位角度');
      }
      if (this.ruleForm.axis.distance === '') {
        this.disabled = false;
        return this.$message.error('请输入轴位位移');
      }
      const dataFront = this.ruleForm.front;
      const dataSide = this.ruleForm.side;
      const dataAxis = this.ruleForm.axis;

      let obj = [
        {
          coordinates: {
            slide: this.frontSlide,
            scale: this.scale,
            multiple: this.multiple,
            dist: this.dist + this.$refs.lineReference1.getNode().y(),
            actual: this.actual,
            topCenter: this.getLastPoints(this.$refs.frontTopLineCenter.getNode().points(), this.frontTopTool),
            bottomCenter: this.getLastPoints(this.$refs.frontBottomLineCenter.getNode().points(), this.frontBottomTool),
            section: {
              x: this.$refs.frontSection.getNode().x() + this.frontBottomTool.x(),
              y: this.$refs.frontSection.getNode().y() + this.frontBottomTool.y(),
            },
            tl1: {
              x: this.$refs.frontTopL1.getNode().x() + this.frontTopTool.x(),
              y: this.$refs.frontTopL1.getNode().y() + this.frontTopTool.y(),
            },
            tr1: {
              x: this.$refs.frontTopR1.getNode().x() + this.frontTopTool.x(),
              y: this.$refs.frontTopR1.getNode().y() + this.frontTopTool.y(),
            },
            tl2: {
              x: this.$refs.frontTopL2.getNode().x() + this.frontTopTool.x(),
              y: this.$refs.frontTopL2.getNode().y() + this.frontTopTool.y(),
            },
            tr2: {
              x: this.$refs.frontTopR2.getNode().x() + this.frontTopTool.x(),
              y: this.$refs.frontTopR2.getNode().y() + this.frontTopTool.y(),
            },
            //
            bl1: {
              x: this.$refs.frontBottomL1.getNode().x() + this.frontBottomTool.x(),
              y: this.$refs.frontBottomL1.getNode().y() + this.frontBottomTool.y(),
            },
            br1: {
              x: this.$refs.frontBottomR1.getNode().x() + this.frontBottomTool.x(),
              y: this.$refs.frontBottomR1.getNode().y() + this.frontBottomTool.y(),
            },
            bl2: {
              x: this.$refs.frontBottomL2.getNode().x() + this.frontBottomTool.x(),
              y: this.$refs.frontBottomL2.getNode().y() + this.frontBottomTool.y(),
            },
            br2: {
              x: this.$refs.frontBottomR2.getNode().x() + this.frontBottomTool.x(),
              y: this.$refs.frontBottomR2.getNode().y() + this.frontBottomTool.y(),
            },
          },
          flip: dataFront.angleDir,
          flip_angle: dataFront.angle,
          slide: dataFront.distanceDir,
          slide_dis: dataFront.distance
        }, {
          coordinates: {
            slide: this.sideSlide,
            scale: this.scale,
            multiple: this.multiple,
            dist: this.dist + this.$refs.lineReference1.getNode().y(),
            actual: this.actual,
            topCenter: this.getLastPoints(this.$refs.sideTopLineCenter.getNode().points(), this.sideTopTool),
            bottomCenter: this.getLastPoints(this.$refs.sideBottomLineCenter.getNode().points(), this.sideBottomTool),
            section: {
              x: this.$refs.sideSection.getNode().x() + this.sideBottomTool.x(),
              y: this.$refs.sideSection.getNode().y() + this.sideBottomTool.y(),
            },
            tl1: {
              x: this.$refs.sideTopL1.getNode().x() + this.sideTopTool.x(),
              y: this.$refs.sideTopL1.getNode().y() + this.sideTopTool.y(),
            },
            tr1: {
              x: this.$refs.sideTopR1.getNode().x() + this.sideTopTool.x(),
              y: this.$refs.sideTopR1.getNode().y() + this.sideTopTool.y(),
            },
            tl2: {
              x: this.$refs.sideTopL2.getNode().x() + this.sideTopTool.x(),
              y: this.$refs.sideTopL2.getNode().y() + this.sideTopTool.y(),
            },
            tr2: {
              x: this.$refs.sideTopR2.getNode().x() + this.sideTopTool.x(),
              y: this.$refs.sideTopR2.getNode().y() + this.sideTopTool.y(),
            },
            //
            bl1: {
              x: this.$refs.sideBottomL1.getNode().x() + this.sideBottomTool.x(),
              y: this.$refs.sideBottomL1.getNode().y() + this.sideBottomTool.y(),
            },
            br1: {
              x: this.$refs.sideBottomR1.getNode().x() + this.sideBottomTool.x(),
              y: this.$refs.sideBottomR1.getNode().y() + this.sideBottomTool.y(),
            },
            bl2: {
              x: this.$refs.sideBottomL2.getNode().x() + this.sideBottomTool.x(),
              y: this.$refs.sideBottomL2.getNode().y() + this.sideBottomTool.y(),
            },
            br2: {
              x: this.$refs.sideBottomR2.getNode().x() + this.sideBottomTool.x(),
              y: this.$refs.sideBottomR2.getNode().y() + this.sideBottomTool.y(),
            },
          },
          flip: dataSide.angleDir,
          flip_angle: dataSide.angle,
          slide: dataSide.distanceDir,
          slide_dis: dataSide.distance
        }, {
          flip: dataAxis.angleDir,
          flip_angle: dataAxis.angle,
          slide: dataAxis.distanceDir,
          slide_dis: dataAxis.distance
        }
      ]
      let resp = await this.$api.saveNear({
        id: this.id,
        near: this.reference
      })
      if (resp.code) {
        return this.$api.saveParams({
          id: this.id,
          line: this.actual,
          obj: JSON.stringify(obj),
          before_after: 1,
        }).then(res => {
          //比例测量
          if (res.code) {
            if (this.isComplete) {
              return this.$router.push('/post/stand?id=' + this.id);
            } else {
              return this.$router.push('/post/installation?id=' + this.id);
            }
          }
          this.disabled = false;
          this.$message.error(res.msg);
        }).catch(err => {
          console.log(err);
          this.disabled = false;
        })
      }
      this.$message.error(resp.msg);
    },

    reSection (type = 'front') {
      let node = type == 'front' ? this.$refs.frontBottomLineCenter.getNode() : this.$refs.sideBottomLineCenter.getNode();
      let points = node.points();
      let section = type == 'front' ? this.$refs.frontSection.getNode() : this.$refs.sideSection.getNode();
      section.x(this.getPointX(points[2], points[3], points[4], points[5], section.y()));
    },
    sideDataChange () {
      let sideBottomLineCenter = this.$refs.sideBottomLineCenter.getNode();
      let sideTopLineCenter = this.$refs.sideTopLineCenter.getNode();
      //上截面点
      let sideTopIntersection = this.getPointOfIntersection(sideTopLineCenter.points(), this.sideTopTool);
      //下截面点
      let sideBottomIntersection = this.$refs.sideSection.getNode().x() + this.sideBottomTool.x();
      //位移距离
      this.ruleForm.side.distance = Math.floor(Math.abs(sideTopIntersection - sideBottomIntersection) / this.base[1].proportion / this.multiple * 100) / 100;
      //
      let points = sideTopLineCenter.points();
      let toolX = this.sideTopTool.x();
      let toolY = this.sideTopTool.y();
      let Slope1 = this.getLinesSlope(points[2] + toolX, points[3] + toolY, points[4] + toolX, points[5] + toolY);
      points = sideBottomLineCenter.points();
      toolX = this.sideBottomTool.x();
      toolY = this.sideBottomTool.y();
      let Slope2 = this.getLinesSlope(points[2] + toolX, points[3] + toolY, points[4] + toolX, points[5] + toolY);
      this.ruleForm.side.angle = Math.floor(this.getLinesArctan(Slope1, Slope2) * 100) / 100;//角度
    },
    frontDataChange () {
      let frontBottomLineCenter = this.$refs.frontBottomLineCenter.getNode();
      let frontTopLineCenter = this.$refs.frontTopLineCenter.getNode();
      //上截面点
      let FrontTopIntersection = this.getPointOfIntersection(frontTopLineCenter.points(), this.frontTopTool);
      //下截面点
      let FrontBottomIntersection = this.$refs.frontSection.getNode().x() + this.frontBottomTool.x();
      //位移距离
      this.ruleForm.front.distance = Math.floor(Math.abs(FrontTopIntersection - FrontBottomIntersection) / this.base[0].proportion / this.multiple * 100) / 100;
      //轴位位移
      this.ruleForm.axis.distance = Math.floor(Math.abs(this.$refs.lineReference1.getNode().y() + this.dist - this.$refs.frontSection.getNode().y() - this.frontBottomTool.y()) / this.base[0].proportion / this.multiple * 100) / 100;

      //
      let points = frontTopLineCenter.points();
      let toolX = this.frontTopTool.x();
      let toolY = this.frontTopTool.y();
      let Slope1 = this.getLinesSlope(points[2] + toolX, points[3] + toolY, points[4] + toolX, points[5] + toolY);
      points = frontBottomLineCenter.points();
      toolX = this.frontBottomTool.x();
      toolY = this.frontBottomTool.y();
      let Slope2 = this.getLinesSlope(points[2] + toolX, points[3] + toolY, points[4] + toolX, points[5] + toolY);
      this.ruleForm.front.angle = Math.floor(this.getLinesArctan(Slope1, Slope2) * 100) / 100;//角度
    },
    getPointOfIntersection (points, tool) {
      return this.getPointX(points[2] + tool.x(), points[3] + tool.y(), points[4] + tool.x(), points[5] + tool.y(), this.$refs.lineReference1.getNode().y() + this.dist);
    },
    //求直线斜率
    getLinesSlope (x1, y1, x2, y2) {
      return (y2 - y1) / (x2 - x1);
    },
    //求直线夹角
    getLinesArctan (line1, line2) {
      if (line1 == Infinity && line2 == Infinity) {
        return 0;
      } else if (line1 == Infinity) {
        let tan = Math.atan(line2);
        let angle = Math.abs(tan * 180 / Math.PI);
        return 90 - angle;
      } else if (line2 == Infinity) {
        let tan = Math.atan(line1);
        let angle = Math.abs(tan * 180 / Math.PI);
        return 90 - angle;
      }
      let tan_k = 0; //直线夹角正切值
      let lines_arctan;//直线斜率的反正切值
      tan_k = (line2 - line1) / (1 + line2 * line1); //求直线夹角的公式
      lines_arctan = Math.abs(Math.atan(tan_k) * 180.0 / Math.PI);
      return lines_arctan;
    },

    //求直线方程式
    getLinearEquation (first_x, first_y, second_x, second_y) {
      //一般式 Ax+By+C=0
      let A = second_y - first_y;
      let B = first_x - second_x;
      let C = second_x * first_y - first_x * second_y;
      return [A, B, C]
    },
    //求交点
    getIntersectPointofLines (x1, y1, x2, y2, x3, y3, x4, y4) {
      let K1 = this.getLinearEquation(x1, y1, x2, y2);
      let A1 = K1[0];
      let B1 = K1[1];
      let C1 = K1[2];
      let K2 = this.getLinearEquation(x3, y3, x4, y4);
      let A2 = K2[0];
      let B2 = K2[1];
      let C2 = K2[2];
      let m = A1 * B2 - A2 * B1
      if (m == 0) {
        //平行，无交点
        return null;
      } else {
        let x = (C2 * B1 - C1 * B2) / m
        let y = (C1 * A2 - C2 * A1) / m
        return [x, y]
      }
    },
    //退出登录
    logout () {
      this.$confirm('是否退出登录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.logout().then(res => {
          if (res.code) {
            return this.$router.push('/login')
          }
          this.$message.error(res.msg);
        })
          .catch(err => {
            // this.$message.error('系统繁忙');
          })
      }).catch(() => {

      });
    },
    //设置坐标
    setCoordinates () {
      const frontCoordinates = this.base[0].coordinates ? JSON.parse(this.base[0].coordinates) : false;
      const sideCoordinates = this.base[1].coordinates ? JSON.parse(this.base[1].coordinates) : false;
      if (frontCoordinates && sideCoordinates) {
        this.actual = frontCoordinates.actual ? frontCoordinates.actual : this.actual;
        this.dist = frontCoordinates.dist ? frontCoordinates.dist : this.dist;
        // this.multiple = frontCoordinates.multiple ? frontCoordinates.multiple : 1;
        this.scale = frontCoordinates.scale ? frontCoordinates.scale : 1
        setTimeout(() => { this.zoom() }, 10)
        this.lock = true; //锁定
        //设置点
        //正位
        this.$refs.frontTopL1.getNode().x(frontCoordinates.tl1.x)
        this.$refs.frontTopL1.getNode().y(frontCoordinates.tl1.y)
        //
        this.$refs.frontTopL2.getNode().x(frontCoordinates.tl2.x)
        this.$refs.frontTopL2.getNode().y(frontCoordinates.tl2.y)
        //
        this.$refs.frontTopR1.getNode().x(frontCoordinates.tr1.x)
        this.$refs.frontTopR1.getNode().y(frontCoordinates.tr1.y)
        //
        this.$refs.frontTopR2.getNode().x(frontCoordinates.tr2.x)
        this.$refs.frontTopR2.getNode().y(frontCoordinates.tr2.y)
        //
        this.$refs.frontBottomL1.getNode().x(frontCoordinates.bl1.x)
        this.$refs.frontBottomL1.getNode().y(frontCoordinates.bl1.y)
        //
        this.$refs.frontBottomL2.getNode().x(frontCoordinates.bl2.x)
        this.$refs.frontBottomL2.getNode().y(frontCoordinates.bl2.y)
        //
        this.$refs.frontBottomR1.getNode().x(frontCoordinates.br1.x)
        this.$refs.frontBottomR1.getNode().y(frontCoordinates.br1.y)
        //
        this.$refs.frontBottomR2.getNode().x(frontCoordinates.br2.x)
        this.$refs.frontBottomR2.getNode().y(frontCoordinates.br2.y)
        //侧位
        this.$refs.sideTopL1.getNode().x(sideCoordinates.tl1.x)
        this.$refs.sideTopL1.getNode().y(sideCoordinates.tl1.y)
        //
        this.$refs.sideTopL2.getNode().x(sideCoordinates.tl2.x)
        this.$refs.sideTopL2.getNode().y(sideCoordinates.tl2.y)
        //
        this.$refs.sideTopR1.getNode().x(sideCoordinates.tr1.x)
        this.$refs.sideTopR1.getNode().y(sideCoordinates.tr1.y)
        //
        this.$refs.sideTopR2.getNode().x(sideCoordinates.tr2.x)
        this.$refs.sideTopR2.getNode().y(sideCoordinates.tr2.y)
        //
        this.$refs.sideBottomL1.getNode().x(sideCoordinates.bl1.x)
        this.$refs.sideBottomL1.getNode().y(sideCoordinates.bl1.y)
        //
        this.$refs.sideBottomL2.getNode().x(sideCoordinates.bl2.x)
        this.$refs.sideBottomL2.getNode().y(sideCoordinates.bl2.y)
        //
        this.$refs.sideBottomR1.getNode().x(sideCoordinates.br1.x)
        this.$refs.sideBottomR1.getNode().y(sideCoordinates.br1.y)
        //
        this.$refs.sideBottomR2.getNode().x(sideCoordinates.br2.x)
        this.$refs.sideBottomR2.getNode().y(sideCoordinates.br2.y)

        //设置工具线段
        this.$refs.frontTopLine1.getNode().points([
          this.$refs.frontTopL1.getNode().x(),
          this.$refs.frontTopL1.getNode().y(),
          this.$refs.frontTopR1.getNode().x(),
          this.$refs.frontTopR1.getNode().y()
        ])
        //
        this.$refs.frontTopLine2.getNode().points([
          this.$refs.frontTopL2.getNode().x(),
          this.$refs.frontTopL2.getNode().y(),
          this.$refs.frontTopR2.getNode().x(),
          this.$refs.frontTopR2.getNode().y()
        ])
        //
        this.$refs.frontBottomLine1.getNode().points([
          this.$refs.frontBottomL1.getNode().x(),
          this.$refs.frontBottomL1.getNode().y(),
          this.$refs.frontBottomR1.getNode().x(),
          this.$refs.frontBottomR1.getNode().y()
        ])
        //
        this.$refs.frontBottomLine2.getNode().points([
          this.$refs.frontBottomL2.getNode().x(),
          this.$refs.frontBottomL2.getNode().y(),
          this.$refs.frontBottomR2.getNode().x(),
          this.$refs.frontBottomR2.getNode().y()
        ])
        //
        this.$refs.sideTopLine1.getNode().points([
          this.$refs.sideTopL1.getNode().x(),
          this.$refs.sideTopL1.getNode().y(),
          this.$refs.sideTopR1.getNode().x(),
          this.$refs.sideTopR1.getNode().y()
        ])
        //
        this.$refs.sideTopLine2.getNode().points([
          this.$refs.sideTopL2.getNode().x(),
          this.$refs.sideTopL2.getNode().y(),
          this.$refs.sideTopR2.getNode().x(),
          this.$refs.sideTopR2.getNode().y()
        ])
        //
        this.$refs.sideBottomLine1.getNode().points([
          this.$refs.sideBottomL1.getNode().x(),
          this.$refs.sideBottomL1.getNode().y(),
          this.$refs.sideBottomR1.getNode().x(),
          this.$refs.sideBottomR1.getNode().y()
        ])
        //
        this.$refs.sideBottomLine2.getNode().points([
          this.$refs.sideBottomL2.getNode().x(),
          this.$refs.sideBottomL2.getNode().y(),
          this.$refs.sideBottomR2.getNode().x(),
          this.$refs.sideBottomR2.getNode().y()
        ])
        //截面点
        this.$refs.frontSection.getNode().x(frontCoordinates.section.x);
        this.$refs.frontSection.getNode().y(frontCoordinates.section.y);
        this.$refs.sideSection.getNode().x(sideCoordinates.section.x);
        this.$refs.sideSection.getNode().y(sideCoordinates.section.y);

        this.layer.draw();
        this.layer2.draw();
        return true;
      }
      return false;

    },
    //返回上一步
    lastStep () {
      this.$router.push('/post/level?id=' + this.id);
    },
    frontTopLine1MouseEnter (event) {
      console.log('frontTopLine1MouseEnter')
    },
    lineReference1MouseEnter (event) { // 红色虚线
      console.log('lineReference1MouseEnter')
      this.in_red = true
      if (this.in_green && this.in_red) {
        this.popoverContent = this.$t('popver.qsd')
        this.popoverPosition.top = (+event.evt.screenY - 86) + 'px'
        this.popoverPosition.left = (+event.evt.screenX - 82) + 'px'
        console.log(this.popoverPosition)
        this.popoverShow = true
      }
    },
    lineReference1MouseLeave () {
      this.in_red = false
      this.in_green = false
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    linelevel1MouseEnter (event) { //蓝色虚线
      console.log('linelevel1MouseEnter')
    },
    distanceList1MouseEnter (event) { //紫色虚线
      console.log('distanceList1MouseEnter')
    },
    frontTopLineCenterMouseEnter (event) { //绿色虚线
      console.log('frontTopLineCenterMouseEnter')
      this.popoverContent = this.$t('popver.jdckx')
      this.popoverPosition.top = (+event.evt.screenY - 86) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 110) + 'px'
      console.log(this.popoverPosition)
      this.popoverShow = true
      this.in_green = true
    },
    frontBottomLine1MouseEnter (event) { //黄色小短线
      console.log('frontBottomLine1MouseEnter')
    },
    frontBottomLine2MouseEnter (event) { //黄色小短线
      console.log('frontBottomLine2MouseEnter')
    },
    frontBottomLineCenterMouseEnter (event) { //黄色虚线
      console.log('frontBottomLineCenterMouseEnter')
      this.popoverContent = this.$t('popver.ydckx')
      this.popoverPosition.top = (+event.evt.screenY - 86) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 110) + 'px'
      console.log(this.popoverPosition)
      this.popoverShow = true
    },
    frontBottomLineCenterMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    frontTopLineCenterMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    sideTopLineCenterMouseEnter (event) { //右侧绿色虚线
      console.log('sideTopLineCenterMouseEnter')
      this.popoverContent = this.$t('popver.jdckx')
      this.popoverPosition.top = (+event.evt.screenY - 86) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 110) + 'px'
      console.log(this.popoverPosition)
      this.popoverShow = true
      this.in_green = true
    },
    sideTopLineCenterMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    frontSectionMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    frontSectionMouseEnter (event) { // 左侧紫色点
      console.log('frontSectionMouseEnter')
      this.popoverContent = this.$t('popver.dyd')
      this.popoverPosition.top = (+event.evt.screenY - 86) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 82) + 'px'
      console.log(this.popoverPosition)
      this.popoverShow = true
    },
    lineReference2MouseEnter (event) { //右侧红色线
      console.log('lineReference2MouseEnter')
      this.in_red = true
      if (this.in_green && this.in_red) {
        this.popoverContent = this.$t('popver.qsd')
        this.popoverPosition.top = (+event.evt.screenY - 86) + 'px'
        this.popoverPosition.left = (+event.evt.screenX - 82) + 'px'
        console.log(this.popoverPosition)
        this.popoverShow = true
      }
    },
    llineReference2MouseLeave () {
      this.in_red = false
      this.in_green = false
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    linelevel2MouseEnter () { // 右侧蓝色线
      console.log('linelevel2MouseEnter')
    },
    circleSide2MouseEnter () { //右侧蓝色大点
      console.log('circleSide2MouseEnter')
    },
    distanceList2MouseEnter () { //右侧紫色线
      console.log('distanceList2MouseEnter')
    },
    sideSectionMouseEnter (event) { // 右侧紫色点
      console.log('sideSectionMouseEnter')
      this.popoverContent = this.$t('popver.dyd')
      this.popoverPosition.top = (+event.evt.screenY - 86) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 82) + 'px'
      console.log(this.popoverPosition)
      this.popoverShow = true
    },
    sideBottomLineCenterMouseEnter (event) { //右侧黄色虚线
      console.log('sideBottomLineCenterMouseEnter')
      this.popoverContent = this.$t('popver.ydckx')
      this.popoverPosition.top = (+event.evt.screenY - 86) + 'px'
      this.popoverPosition.left = (+event.evt.screenX - 110) + 'px'
      console.log(this.popoverPosition)
      this.popoverShow = true
    },
    sideBottomLineCenterMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    sideSectionMouseLeave () {
      this.popoverContent = ''
      this.popoverPosition.top = 0
      this.popoverPosition.left = 0
      this.popoverShow = false
    },
    async complete () {
      this.isComplete = true
      this.dialogVisible = true
    }
  }
};
</script>
<style scoped>
.warp {
  width: 1440px;
}

.el-main {
  padding: 0 20px;
  background: #f1f5f7;
}

.head {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-header {
  background: #ffffff;
  text-align: right;
}

.btn-logout {
  display: inline-block;
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 50px;
  position: relative;
}

.btn-logout::before {
  content: "";
  background: url(../../assets/img/off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}

.btn-logout:hover {
  cursor: pointer;
}


.text-upload {
  margin: 17px 0;
  text-align: center;
  color: #7ECDF6;
  text-decoration: underline;
  cursor: pointer;
}

.main {
  /* height: ; */
  display: flex;
  justify-content: space-between;
}

.main .canvas {
  width: 76.88%;
  /* height: 66.94%; */
  height: 540px;
  border-radius: 4px;
  background: #000000;

}

.main .right-side,
.main .left-side {
  width: 17%;
  height: 540px;
  background: #ffffff;
  border-radius: 4px;
  overflow-y: auto;

}

.right-side {
  padding: 0;
  background: #f1f5f7 !important;
}

.main .left-side {
  width: 13%;
}

.main .form {

  font-size: 13px;
  font-weight: 400;
  color: #000000;

}

.container {
  background: #000000;
  position: relative;
  margin: 0 5px;

}

.zoom {
  position: absolute;
  right: 30px;
  bottom: 26px;
  z-index: 99;
}

/* 
.main .btn-submit{
    position: absolute;
    bottom: 25px;
    left:50%;
    transform: translate(-50%,0);
} */
.step-list .item {
  background: #F1F5F7;
  border: 1px solid #364284;
  border-radius: 4px;
  color: #364284;
  margin-top: 14px;
  padding: 12px 22px;
}

.step-list .active {
  background: #364284;
  color: #FFFFFF;
}

.btn-submit,
.btn-comfirm,
.btn-revocation {
  width: 198px;
  height: 38px;
  line-height: 38px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 auto;
  color: #FFFFFF;
  background: #364284;
  border: 1px solid #364284;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.btn-revocation {
  background: #F1F5F7;
  border: 1px solid #364284;
  color: #364284;
  margin-top: 10px;
  margin-bottom: 50px;
}

.content-title {
  font-size: 14px;
  border-bottom: none;

}

.el-form-item {
  margin-bottom: 2px
}

.konvajs-content {
  margin: auto !important;
}

.popover {
  padding: 10px;
  background-color: #fff;
  position: fixed;
  border-radius: 5px;
}

.popover::before {
  content: '';
  position: absolute;
  border: 8px solid;
  border-color: transparent transparent transparent #ffffff;
  right: -16px;
  top: 8px;
}

.el-form-item{
  display: flex;
  flex-direction: column;
}

::v-deep{
  .el-form-item__content{
    margin-left:0 !important;
  }
}
</style>
